<template>
  <div class="dialog">
    <div class="dialog-header">
      <div class="dialog-title">
        CREATE CHARACTER
      </div>
    </div>
    <div class="dialog-content pb-0">
      <b-form
        class="character-form"
        @submit.prevent="createCharacter">
        <b-form-group
          label="Nickname"
          label-for="nickname"
          label-cols="3">
          <b-form-input
            type="text"
            placeholder="Enter Nickname"
            autocomplete="off"
            size="sm"
            v-model.trim="$v.form.nickname.$model"
            :state="!$v.form.nickname.$error"
            @input="$v.form.nickname.$touch()"
          ></b-form-input>
          <b-form-invalid-feedback>
            <span v-if="!$v.form.nickname.required">
              Nickname is required.
            </span>
            <span v-if="!$v.form.nickname.uniqueNickname &&
              $v.form.nickname.required">
              Nickname is already used.
            </span>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="First Name"
          label-for="fname"
          label-cols="3">
          <b-form-input
            type="text"
            placeholder="Enter First Name"
            autocomplete="off"
            size="sm"
            v-model.trim="$v.form.fname.$model"
            :state="!$v.form.fname.$error"
            @input="$v.form.fname.$touch()"
          ></b-form-input>
          <b-form-invalid-feedback>
            First Name is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Last Name"
          label-for="lname"
          label-cols="3">
          <b-form-input
            type="text"
            placeholder="Enter Last Name"
            autocomplete="off"
            size="sm"
            v-model.trim="$v.form.lname.$model"
            :state="!$v.form.lname.$error"
            @input="$v.form.lname.$touch()"
          ></b-form-input>
          <b-form-invalid-feedback>
            Last Name is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Email"
          label-for="email"
          label-cols="3">
          <b-form-input
            type="email"
            placeholder="Enter Email"
            autocomplete="off"
            size="sm"
            v-model.trim="$v.form.email.$model"
            :state="!$v.form.email.$error"
            @input="$v.form.email.$touch()"
          ></b-form-input>
          <b-form-invalid-feedback>
            <span v-if="!$v.form.email.required">Email is required.</span>
            <span v-if="!$v.form.email.email && $v.form.email.required">
              This requires a valid email address.
            </span>
            <span v-if="!$v.form.email.uniqueEmail && $v.form.email.email &&
              $v.form.email.required">
              Email is already registered.
            </span>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          class="align-items-center"
          label="Tier"
          label-for="tier"
          label-cols="3">
          <b-form-checkbox
            v-model="form.tier"
            v-for="tier in tierOptions"
            :key="tier.value"
            :value="tier.value"
            @change="checkTier(tier.value)"
            inline>
            {{ tier.text }}
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          class="align-items-center"
          label="Profile Photo"
          label-for="photo"
          label-cols="3">
          <b-form-file
            accept="image/jpeg, image/png"
            v-model="form.photo"
            @change="$v.form.photo.$touch()"
            :state="!$v.form.photo.$error"
          ></b-form-file>
          <b-form-invalid-feedback :state="!$v.form.photo.$error">
            <span v-if="!$v.form.photo.required">
              Profile Photo is required.
            </span>
            <span v-if="!$v.form.photo.validPhoto && $v.form.photo.required">
              Invalid file type.
            </span>
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="d-flex justify-content-end">
          <b-button
            type="submit"
            variant="outline-success"
            :disabled="$v.form.$invalid">
            Submit
          </b-button>
          <b-button
            class="ml-1"
            type="button"
            variant="outline-danger"
            @click="$emit('close')">
            Cancel
          </b-button>
        </div>
      </b-form>
    </div>

    <AppLoaderAdmin :isLoading="loading" />
  </div>
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators';

  export default {
    data() {
      return {
        loading        : false,
        uniqueEmail    : true,
        uniqueNickname : true,
        form           : {
          nickname : null,
          fname    : null,
          lname    : null,
          email    : null,
          tier     : [],
          photo    : null,
        },
        tierOptions : [
          { text : 'Free', value : 0 },
          { text : 'Champion', value : 1 },
        ],
        validPhoto : false,
        userId     : null,
        progress   : null,
      }
    },
    components : {
      'AppLoaderAdmin' : () => import('@/components/layout/AppLoaderAdmin'),
    },
    watch : {
      'form.nickname'(val) {
        this.uniqueNickname = true;
        if (val)
          this.checkNickname();
      },
      'form.email'(val) {
        this.uniqueEmail = true;
        if (val)
          this.checkEmail();
      },
      'form.photo'(val) {
        this.validPhoto = true;
        if (val && !val.type.startsWith("image/")) {
          this.$nextTick(() => {
            this.validPhoto = false;
          });
        }
      },
    },
    methods : {

      /**
       * Creat Character Account
       */
      createCharacter() {
        this.loading = true;
        this.$http.post('api/user', {
          nickname    : this.form.nickname,
          firstname   : this.form.fname,
          lastname    : this.form.lname,
          email       : this.form.email,
          userType    : 7,
          password    : 'healthyhiphop',
          premiumType : (this.form.tier == 1) ? 1 : 0,
        }).then(response => {
          this.userId = response.data.userId;
          this.uploadProfilePhoto();
        }).catch(() => {
          this.loading = false;
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
       * Upload Profile Photo
       */
      async uploadProfilePhoto() {
        const formData = new FormData();
        formData.append('file', this.form.photo);
        formData.append('userId', this.userId);

        try {
          const upload = await this.$http.post(
            'api/upload/user/profile',
            formData, {
              onUploadProgress : event =>
                this.progress = Math.round(event.loaded * 100 / event.total),
            });

          if (upload)
            this.sendActivationEmail();
        } catch(err) {
          this.loading = false;
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed!',
            text  : 'Oops! Something went wrong!',
          });
        }
      },

      /**
       * Send Activation Email
       */
      sendActivationEmail() {
        this.$http.post('auth/characterActivationEmail', {
          to     : this.form.email,
          name   : this.form.fname,
          userId : this.userId,
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : `Added <b>${this.form.fname}</b> succesfully!`,
          });
          this.loading = false;
          this.$parent.$emit('reload-table');
          this.$emit('close');
        }).catch(() => {
          this.loading = false;
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
       * Check if Nickname is already used
       */
      // eslint-disable-next-line no-undef
      checkNickname : _.debounce(function () {
        this.$http.get('api/user/nickname', {
          params : {
            email    : this.form.nickname,
            nickname : this.form.nickname,
          },
        }).then(response => {
          // swapped value since api returns true
          //  if nickname exists hence nickname is not unique
          this.uniqueNickname = (response.data) ? false : true;
        }).catch(() => {});
      }, 500),

      /**
       * Check if Email is already registered
       */
      // eslint-disable-next-line no-undef
      checkEmail : _.debounce(function () {
        this.$http.get('api/user/email', {
          params : {
            email : this.form.email,
          },
        }).then(response => {
          // swapped value since api returns true
          //  if email exists hence email is not unique
          this.uniqueEmail = (response.data) ? false : true;
        }).catch(() => {});
      }, 500),

      /**
       * Check Tier
       * @param tier
       */
      checkTier(tier) {
        if (tier == 0) {
          if (!this.form.tier.includes(1))
            this.form.tier.push(1);
        }

        if (tier == 1) {
          if (this.form.tier.includes(0))
            this.form.tier = [];
        }
      },
    },
    validations() {
      return {
        form : {
          nickname : {
            required,
            uniqueNickname() {
              return this.uniqueNickname;
            },
          },
          fname : { required },
          lname : { required },
          email : {
            required,
            email,
            uniqueEmail() {
              return this.uniqueEmail;
            },
          },
          tier  : { required },
          photo : { required },
        },
      }
    },
  }
</script>